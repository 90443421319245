import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Divider } from '@mui/material'

import { Tag } from '../../../../api/core'
import { BooleanMap } from '../../../../types/BooleanMap'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { useLiveEventsCommonFiltersAnalyticsEvents } from '../../hooks/useLiveEventsTrackerAnalyticsEvents'
import {
  LiveEventsCommonFiltersData,
  TLiveEventsCommonFilters,
  LiveEventsCommonFilterKeys,
  LiveEventsCommonFilterGroup,
  LiveEventsCommonFiltersSelection,
} from '../../types/LiveEventsCommonFilters'
import LiveEventsAppearanceSelector from '../LiveEventsAppearanceSelector/LiveEventsAppearanceSelector'
import LiveEventsCalendarAdditionalDataSelector from '../LiveEventsCalendarAdditionalDataSelector/LiveEventsCalendarAdditionalDataSelector'
import LiveEventsDurationsSelector from '../LiveEventsDurationsSelector/LiveEventsDurationsSelector'
import LiveEventsMotivationsSelector from '../LiveEventsMotivationsSelector/LiveEventsMotivationsSelector'
import LiveEventsSecondaryTaxonomySelector from '../LiveEventsSecondaryTaxonomySelector/LiveEventsSecondaryTaxonomySelector'
import LiveEventsTagsSelector from '../LiveEventsTagsSelector/LiveEventsTagsSelector'

type LiveEventsCommonFiltersProps = {
  data: LiveEventsCommonFiltersData
  filters: TLiveEventsCommonFilters
  onChange: (filters: TLiveEventsCommonFilters) => void
  children?: ReactNode
}

export const LiveEventsCommonFilters: FC<LiveEventsCommonFiltersProps> = ({ data, filters, onChange, children }) => {
  const { t } = useTranslation()
  const internalUser = useRoleCheck(RoleEnum.internal) // NOTE: Remove Internal role check when we want to release Live Event Motivations
  const { handleTrackCommonFilterGroupSelectAllEvent, handleTrackFilterChangedEvent } = useLiveEventsCommonFiltersAnalyticsEvents()
  const handleChange = <T extends LiveEventsCommonFilterKeys>(name: T, newValue: TLiveEventsCommonFilters[T], selection?: LiveEventsCommonFiltersSelection) => {
    if (selection) {
      handleTrackFilterChangedEvent(selection)
    }

    const newFilters = {
      ...filters,
      [name]: newValue,
    }

    onChange(newFilters)
  }

  const handleSelectAll = (name: LiveEventsCommonFilterKeys, value: any) => {
    handleTrackCommonFilterGroupSelectAllEvent(name)
    handleChange(name, value)
  }

  return (
    <>
      <LiveEventsCalendarAdditionalDataSelector
        calendarAdditionalDatas={data.additionalDatas}
        selectedCalendarAdditionalDataIdsMap={filters.additionalDatas}
        onAdditionalDataSelectChange={(additionalDatas: { [dataId: string]: boolean }, data) =>
          handleChange(LiveEventsCommonFilterKeys.AdditionalDatas, additionalDatas, { ...data, type: LiveEventsCommonFilterGroup.GameData })
        }
        onAdditionalDataSelectionCleared={() => handleChange(LiveEventsCommonFilterKeys.AdditionalDatas, {})}
        onAdditionalDataSelectAll={() =>
          handleSelectAll(
            LiveEventsCommonFilterKeys.AdditionalDatas,
            data.additionalDatas.reduce((acc, data) => ({ ...acc, [data.id]: true }), {} as { [dataId: string]: boolean })
          )
        }
        open
      />

      <Divider light sx={{ marginBottom: 1, marginTop: 1 }} />

      <LiveEventsTagsSelector
        selectableLiveEventTags={data.liveEventTags}
        selectedLiveEventTags={filters.liveEventTags}
        onLiveEventsTagSelectChange={(selectedTags: Tag[], selection: { id: string; name: string }) =>
          handleChange(LiveEventsCommonFilterKeys.LiveEventTags, selectedTags, { ...selection, type: LiveEventsCommonFilterGroup.EventType })
        }
        onLiveEventsTagGroupSelected={(selectedTags: Tag[], selection: { id: string; name: string }) =>
          handleChange(LiveEventsCommonFilterKeys.LiveEventTags, selectedTags, { ...selection, type: LiveEventsCommonFilterGroup.EventTypeGroup })
        }
        onLiveEventsTagSelectionCleared={() => handleChange(LiveEventsCommonFilterKeys.LiveEventTags, [])}
        onLiveEventsTagSelectAll={() => handleSelectAll(LiveEventsCommonFilterKeys.LiveEventTags, data.liveEventTags)}
        open
      />

      <>
        <Divider light sx={{ marginBottom: 1, marginTop: 1 }} />

        <LiveEventsSecondaryTaxonomySelector
          selectableLiveEventSecondaryTags={data.liveEventSecondaryTags}
          onLiveEventsSecondaryTaxonomySelectChange={(selectedTags: Tag[], selection: { id: string; name: string }) =>
            handleChange(LiveEventsCommonFilterKeys.LiveEventSecondaryTags, selectedTags, { ...selection, type: LiveEventsCommonFilterGroup.EventType })
          }
          selectedLiveEventSecondaryTags={filters.liveEventSecondaryTags}
          onLiveEventsSecondaryTaxonomyCleared={() => handleChange(LiveEventsCommonFilterKeys.LiveEventSecondaryTags, [])}
          onLiveEventsSecondaryTaxonomySelectAll={() => handleSelectAll(LiveEventsCommonFilterKeys.LiveEventSecondaryTags, data.liveEventSecondaryTags)}
          open
        />
      </>

      {internalUser && (
        <>
          <Divider light sx={{ marginBottom: 1, marginTop: 1 }} />

          <LiveEventsMotivationsSelector
            selectableMotivations={data.motivations}
            selectedMotivationsIdsMap={filters.motivations}
            selectableArchetypes={data.archetypes}
            selectedArchetypesIdsMap={filters.archetypes}
            onLiveEventsMotivationSelectChange={(motivations: BooleanMap, motivation) =>
              handleChange(LiveEventsCommonFilterKeys.Motivations, motivations, {
                id: motivation,
                name: motivation,
                type: LiveEventsCommonFilterGroup.Motivation,
              })
            }
            onLiveEventsMotivationsSelectionCleared={() => {
              handleChange(LiveEventsCommonFilterKeys.Motivations, {})
              handleChange(LiveEventsCommonFilterKeys.Archetypes, {})
            }}
            onLiveEventsMotivationsSelectAll={() => {
              handleSelectAll(
                LiveEventsCommonFilterKeys.Motivations,
                data.motivations.reduce((acc, motivation) => ({ ...acc, [motivation]: true }), {} as BooleanMap)
              )

              handleSelectAll(
                LiveEventsCommonFilterKeys.Archetypes,
                data.archetypes.reduce((acc, archetype) => ({ ...acc, [archetype]: true }), {} as BooleanMap)
              )
            }}
            onLiveEventsArchetypeSelectChange={(archetypes: BooleanMap, motivationKey) =>
              handleChange(LiveEventsCommonFilterKeys.Archetypes, archetypes, {
                id: motivationKey,
                name: motivationKey,
                type: LiveEventsCommonFilterGroup.Archetype,
              })
            }
            open
          />
        </>
      )}

      <Divider light sx={{ marginBottom: 1, marginTop: 1 }} />

      <LiveEventsDurationsSelector
        liveEventDurations={data.liveEventDurations}
        selectedDurationIdsMap={filters.liveEventDurations}
        onLiveEventsDurationSelectChange={(durations: BooleanMap, duration) =>
          handleChange(LiveEventsCommonFilterKeys.LiveEventDurations, durations, { ...duration, type: LiveEventsCommonFilterGroup.Duration })
        }
        onLiveEventsDurationSelectionCleared={() => handleChange(LiveEventsCommonFilterKeys.LiveEventDurations, {})}
        onLiveEventsDurationSelectAll={() =>
          handleSelectAll(
            LiveEventsCommonFilterKeys.LiveEventDurations,
            data.liveEventDurations.reduce((acc, duration) => ({ ...acc, [duration.id]: true }), {} as BooleanMap)
          )
        }
        open
      />

      <Divider light sx={{ marginBottom: 1, marginTop: 1 }} />

      <LiveEventsAppearanceSelector
        liveEventAppearances={data.liveEventAppearances}
        selectedAppearanceIdsMap={filters.liveEventAppearances}
        onLiveEventsAppearanceSelectChange={(appearances: BooleanMap, appearance) =>
          handleChange(LiveEventsCommonFilterKeys.LiveEventAppearances, appearances, {
            id: appearance,
            name: t('live-events:appearance_event_type_' + appearance),
            type: LiveEventsCommonFilterGroup.Appearance,
          })
        }
        onLiveEventsAppearanceSelectionCleared={() => handleChange(LiveEventsCommonFilterKeys.LiveEventAppearances, {})}
        onLiveEventsAppearanceSelectAll={() =>
          handleSelectAll(
            LiveEventsCommonFilterKeys.LiveEventAppearances,
            data.liveEventAppearances.reduce((acc, appearance) => ({ ...acc, [appearance]: true }), {} as BooleanMap)
          )
        }
        open
      />
    </>
  )
}
